<template>
  <div class="EduHome">
    <LoginHeader />
    <main class="page-container">
      <div class="RegisterMian">
        <div class="RegisterTitle">重置密码</div>
        <div class="RegisterItem">
          <i></i>通过您的手机,可以设置新的密码登陆。
        </div>
        <div class="RegisterFrom">
          <div class="RegisterFromTitle">基本信息</div>
          <div class="RegisterFromTable">
            <table width="100%">
              <tr>
                <td>
                  <div class="FontTitle">手机号码</div>
                  <div class="FontInput">
                    <input
                      v-model="RegisterFrom.cellphone"
                      type="text"
                      placeholder="请填写手机号"
                    />
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">验证码</div>
                  <div class="FontInput">
                    <input
                      v-model="RegisterFrom.captcha"
                      type="text"
                      placeholder="验证码"
                    />
                  </div>
                </td>
                <td>
                  <div class="FontTitle">&nbsp;</div>
                  <div>
                    <img class="code" :src="codeUrl" @click="changeCode" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">手机验证码</div>
                  <div class="FontInput">
                    <input
                      v-model="RegisterFrom.verifier"
                      type="text"
                      placeholder="请填写手机验证码"
                    />
                  </div>
                </td>
                <td>
                  <div class="FontTitle">&nbsp;</div>
                  <div>
                    <button
                      v-show="show"
                      @click="checkSendSmscode"
                      class="FontButton"
                      type="button"
                    >
                      获取验证码
                    </button>
                    <button v-show="!show" class="FontButton" type="button">
                      重新获取{{ count }}S
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="RegisterFromTitle">密码设置</div>
          <div class="RegisterFromTable">
            <table width="100%">
              <tr>
                <td>
                  <div class="FontTitle">密码设置</div>
                  <div class="FontInput">
                    <input
                      v-model="RegisterFrom.password"
                      type="password"
                      placeholder="输入新密码"
                    />
                  </div>
                </td>
                <td>
                  <div class="FontTitle">&nbsp;</div>
                  <div style="color: #bfbfbf">
                    8-16位，可包含字母大小写、数字等
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">再次输入密码</div>
                  <div class="FontInput">
                    <input
                      v-model="RegisterFrom.password1"
                      type="password"
                      placeholder="再次输入新密码"
                    />
                  </div>
                </td>
                <td>
                  <div class="FontTitle">&nbsp;</div>
                  <div style="color: #bfbfbf">
                    8-16位，可包含字母大小写、数字等
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">&nbsp;</div>
                  <div class="login-protocol">
                    <label
                      ><input v-model="checkedInfo" type="checkbox" />同意<span
                        >《注册协议》&《隐私政策》</span
                      ></label
                    >
                  </div>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
        <button @click="RegisterBut" type="button" class="RegisterButton">
          提交
        </button>
      </div>
    </main>
    <!-- <footer class="LoginFooter">
      <div class="FooterCenter">
        <ul>
          <li>首页华路</li>
          <li>•</li>
          <li>国际名校</li>
          <li>•</li>
          <li>资讯中心</li>
          <li>•</li>
          <li>华路校友会</li>
          <li>•</li>
          <li>华路商学院简介</li>
          <li>•</li>
          <li>我要约课</li>
        </ul>
        <div class="FooterRight">
          <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
          <p>www.cufehl.com 粤ICP备14094585号-1</p>
        </div>
      </div>
    </footer> -->
    <login-footer />
    <!-- <bind-we-chat ref="bindWeChat" />
    <bind-we-chat-tip ref="bindWeChatTip" /> -->
  </div>
</template>

<script>
import LoginFooter from "@/components/LoginFooter";
import LoginHeader from "@/components/LoginHeader";
// import BindWeChat from "./components/BindWeChat.vue";
// import BindWeChatTip from "./components/BindWeChatTip.vue"
import md5 from "js-md5";
import {
  getImageCaptcha,
  sendSmscode,
  resetPassword,
} from "@/api/authController";
export default {
  name: "ResetPassword",
  data() {
    return {
      checkedInfo: true,
      codeUrl: "",
      RegisterFrom: {},
      show: true,
      count: "",
      timer: null,
    };
  },
  components: {
    LoginHeader,
    LoginFooter,
    // BindWeChat,
    // BindWeChatTip,
  },
  created() {
    this.changeCode();
  },
  methods: {
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async RegisterBut() {
      //this.$refs["bindWeChat"].showEdit();
      //提交注册
      console.log(this.RegisterFrom);
      if (!this.RegisterFrom.cellphone) {
        this.$baseMessage(
          "请填写手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.RegisterFrom.cellphone)) {
        this.$baseMessage(
          "请输入有效的手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.captcha) {
        this.$baseMessage("请填写验证码", "warning", "vab-hey-message-warning");
        return;
      }
      if (this.RegisterFrom.captcha.length != 4) {
        this.$baseMessage(
          "验证码位数不对",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.verifier) {
        this.$baseMessage(
          "请填写手机验证码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (this.RegisterFrom.verifier.length != 6) {
        this.$baseMessage(
          "手机验证码位数不对",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      //let reg = !/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}/   必须包含大小写字母 8-16
      if (!this.RegisterFrom.password) {
        this.$baseMessage("请填写密码", "warning", "vab-hey-message-warning");
        return;
      }
      if (
        this.RegisterFrom.password.length < 8 ||
        this.RegisterFrom.password.length > 16
      ) {
        this.$baseMessage(
          "密码必须在8-16的范围内",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.password1) {
        this.$baseMessage(
          "请输入第二次密码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (this.RegisterFrom.password != this.RegisterFrom.password1) {
        this.$baseMessage(
          "两次输入的密码不一致",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.checkedInfo) {
        this.$baseMessage(
          "协议条款未填写",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      this.register();
      // let json = {};
      // json.cellphone = this.RegisterFrom.cellphone;
      // const { code, data } = await checkUsername(json);
      // if (code == 20000 && data.exists) {
      //   this.$baseMessage("手机号码已注册", "error", "vab-hey-message-error");
      // } else if (code == 20000 && !data.exists) {
      //   this.register();
      // }
    },
    async register() {
      let regFrom = {};
      regFrom.username = this.RegisterFrom.cellphone;
      regFrom.cellphone = this.RegisterFrom.cellphone;
      regFrom.newPassword1 = md5(this.RegisterFrom.password);
      regFrom.newPassword2 = md5(this.RegisterFrom.password);
      regFrom.verifier = this.RegisterFrom.verifier;
      let verifierFrom = {};
      verifierFrom.verifier = this.RegisterFrom.verifier;
      verifierFrom.captcha = this.RegisterFrom.captcha;
      // const { code, msg } = await resetPassword(regFrom, verifierFrom);
      // if (code == 20000) {
      //   this.$baseMessage("修改成功", "success", "vab-hey-message-success");
      //   this.$refs["bindWeChat"].showEdit();
      //   this.$router.push({
      //     path: "/",
      //   });
      // } else {
      //   this.$baseMessage(
      //     "修改失败,code:" + code + ",msg:" + msg,
      //     "error",
      //     "vab-hey-message-error"
      //   );
      // }
      resetPassword(regFrom, verifierFrom).then((res) => {
        if (res.code == 20000) {
          this.$router.push({
            path: "/Login",
          });
        } else {
          this.$baseMessage(
            "修改失败,code:" + res.code + ",msg:" + res.msg,
            "error",
            "vab-hey-message-error"
          );
          this.changeCode();
          this.RegisterFrom.captcha = "";
        }
      });
    },
    changeCode() {
      this.codeUrl = getImageCaptcha() + "?timestamp=" + new Date().getTime();
    },
    checkSendSmscode() {
      if (!this.RegisterFrom.cellphone) {
        this.$baseMessage(
          "请填写手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.RegisterFrom.cellphone)) {
        this.$baseMessage(
          "请输入有效的手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.captcha) {
        this.$baseMessage("请填写验证码", "warning", "vab-hey-message-warning");
        return;
      }
      if (this.RegisterFrom.captcha.length != 4) {
        this.changeCode();
        this.RegisterFrom.captcha = "";
        this.$baseMessage(
          "验证码位数不对",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      this.checkCellphone();
    },
    async checkCellphone() {
      // let json = {};
      // json.username = this.RegisterFrom.cellphone;
      this.sendSmscode();
      // const { code, data } = await checkUsername(json);
      // if (code == 20000 && data.exists) {
      //   this.$baseMessage("手机号码已注册", "error", "vab-hey-message-error");
      // } else if (code == 20000 && !data.exists) {
      //   this.sendSmscode();
      // } else {
      //   this.$baseMessage("请求超时请重试", "error", "vab-hey-message-error");
      // }
    },
    async sendSmscode() {
      let json = {};
      json.captcha = this.RegisterFrom.captcha;
      json.cellphone = this.RegisterFrom.cellphone;
      //const { code, msg } = await sendSmscode(json);
      sendSmscode(json).then((res) => {
        if (res.code == 20000) {
          this.getCode();
        } else {
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  flex: 1;
  padding: 0px 40px 42px;
  .RegisterMian {
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .RegisterTitle {
      font-size: 35px;
    }
    .RegisterItem {
      background: #f2f3f3;
      border: 1px solid #d8d8d8;

      border-radius: 6px;
      padding: 20px 35px;
      margin-top: 20px;
      line-height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;

      i {
        float: left;
        width: 24px;
        height: 24px;
        display: block;
        background: url("../../assets/register/item-icon.png") no-repeat center;
        background-size: cover;
        margin-right: 10px;
      }
    }
    .RegisterFrom {
      margin-top: 25px;
      background: #f2f3f3;
      border: 1px solid #d8d8d8;

      border-radius: 6px;
      padding: 35px 100px;
      .RegisterFromTitle {
        font-size: 30px;
        line-height: 60px;
      }
      .RegisterFromTable {
        margin: 0 -15px;
        border: 0;
        padding-bottom: 20px;
        td {
          padding: 0 15px;
          box-sizing: border-box;
          width: 50%;
          .FontTitle {
            line-height: 40px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .FontInput {
            height: 45px;
            background: #ffffff;
            border: 1px solid #989898;

            border-radius: 3px;
            box-sizing: content-box;
            font-size: 0;
            input {
              width: 100%;
              box-sizing: border-box;
              border: 0;
              padding: 0 15px;
              height: 45px;
              font-size: 16px;
              border: 0;
              background: transparent;
            }
          }
        }
      }
    }
    .RegisterButton {
      background: #173d7c;
      border-radius: 10px;
      cursor: pointer;
      border: 0;
      font-size: 16px;
      color: #ffffff;
      margin-top: 50px;
      width: 149px;
      height: 50px;

      &:hover {
        background: #244f96;
      }
    }
  }
}
.FontButton {
  padding: 0 15px;
  height: 45px;
  font-size: 16px;
  border: 1px solid #989898;
  color: #989898;
}
.code {
  cursor: pointer;
  height: 45px;
  margin-top: 3px;
}

.login-protocol {
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  span {
    color: #173d7c;
  }
}

.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
